<template>
  <main id="tests">
    <img class="prev" src="../assets/chevron-left.svg" alt="next" @click="$emit('move', { transitionType: 'prev', to: '/hej' })">
    <img class="next" src="../assets/chevron-right.svg" alt="next" @click="$emit('move', { transitionType: 'next', to: '/information' })">
    <section class="content">
      <section class="intro" v-hammer:swipe.left.right="goTo">
        <pager :activePage="3" />
        <h1 class="main">Självkännedom</h1>
      </section>
      <article class="floating">
        <h1>{{tests.rubrik}}</h1>
        <div v-html="tests.content"></div>
        <aside class="inline-menu">
          <a href="#" @click="showTest('/test/personlighetstestet')">Personlighetstestet</a>
          <a href="#" @click="showTest('/test/programtestet')">Programtestet</a>
          <a href="#" @click="showTest('/test/valpaverkan')">Vad påverkar mitt val?</a>
        </aside>
      </article>
    </section>
  </main>
</template>

<script>

import pager from '@/components/Pager';

export default {
  name: 'tests',
  components: {
    pager
  },
  computed: {
    tests(){
      return this.$store.getters.testIntro[0];
    }
  },
  methods: {
    goTo(e){
      if(e.direction === 2) {
        this.$emit('move', { transitionType: 'next', to: '/information' });
      } else {
        this.$emit('move', { transitionType: 'prev', to: '/hej' });
      }
    },
    showTest(testUrl){
      this.$emit('move', { transitionType: 'reveal', to: testUrl });
    }
  }
}
</script>

<style lang="scss">
@import '../scss/variables';

#tests {
  background: $darkgrey;

  .intro {
    
    .pager {
      position: absolute;
      top: 0;
    }
    
    .floating {

      div {
        
        p {
          &:first-child {
            font-weight: 700;
          }
        }
      }  
    }
  }
}

</style>

